import React from 'react';
import '../style/expertises.sass'; // Import your Sass file
import expertisesBackground from '../../static/img/expertises-circles-background.png';

const ExpertisesCircles = ({ data }) => {
  return (
    <div className='expertise-circles-section'>
      <div className="bg">
        <div className="item item1" style={{ backgroundColor: "#FFEED4" }}></div>
        <div className="item item2" style={{ backgroundColor: "#DBD9E8" }}></div>
        <div className="item item3" style={{ backgroundColor: "#FFDDE2" }}></div>
      </div>

      <div className="cover" style={{ backgroundImage: `url(${expertisesBackground})` }}>
        {
          data.map((item, index) => {
            return (
              <div key={index} className={`item-cover item-cover${index+1}`} style={{ backgroundColor: item.backgroundColor }}>
                <div className={`expertise-icon-section expertise-icon-section${index+1}`} style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}><img src={item.icon} alt={item.title} className={`expertise-icon expertise-icon${index+1}`} /></div>
                <div style={{width: "50%"}}><h3 className={`title title${index+1}`}>{item.title}</h3></div>
                <div style={{width: "85%"}}><p className={`description description${index+1}`}>{item.description}</p></div>
              </div>
            );
          })
        }
      </div>
    </div>
  );
}

export default ExpertisesCircles;
