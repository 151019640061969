import React, { useState, useEffect, useRef } from "react";
import '../style/homePageAnimation.sass';
import useWindowSize from '../hooks/useWindowSize';



const HomePageAnimation = ({points}) => {
  const [showText, setShowText] = useState(false);
  const { width } = useWindowSize();
  const timerRef = useRef(null);
  const videoRef = useRef(null);

  const handleVideoPlay = () => {
    const currentTime = videoRef.current.currentTime;
    const remainingTime = 10 - currentTime;

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    if (remainingTime > 0) {
      timerRef.current = setTimeout(() => {
        setShowText(true);
      }, remainingTime * 1000);
    } else {
      setShowText(true);
    }
  };

  const handleVideoPause = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  };

  const handleVideoEnded = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  };


  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('canplay', handleVideoPlay);
      if (videoRef.current.readyState >= 3) {
        handleVideoPlay();
      }
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('canplay', handleVideoPlay);
      }
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return (
    <div className="video-container">
      <video
        disablePictureInPicture
        ref={videoRef}
        className="video-element"
        src="img/Home_Page_Animation_v1.mp4"
        autoPlay
        muted
        onPlay={handleVideoPlay}
        onPause={handleVideoPause}
        onEnded={handleVideoEnded}
      ></video>
      <div className="overlay">
        {showText && points.map((point, index) => (
          <div
            key={index}
            className="point"
            style={{ top: point.top, left: point.left }}
          ></div>
        ))}
        {showText && points.map((point, index) => (
          <div key={index} className='hover-text' style={{ top: width > 425 ? point.textTop : point.mobileTextTop, left: width > 425 ? point.textLeft: point.mobileTextLeft, width: width > 425 ? point.width : point.mobileWidth }}>
            <h2 className="hero-title">{point.title}</h2>
            <p className="hero-description">{point.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomePageAnimation;