import * as React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "../style/global-style.sass";
import "../style/testimonial.sass";
import useWindowSize from "../hooks/useWindowSize";

const Testimonials = ({ testimonials }) => {
  const { width } = useWindowSize();
  const settings = {
    dots: width > 768 ? true: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 1,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 8000,
  };
  
  return (
    <section className='testimonials'>
      <h1 className='testimonial-title'>{testimonials[0]?.caption}</h1>
      <div className="testimonial-start-quote">
        <img className="start-quote" src={testimonials[0]?.startQuote} alt="Quote Start" />
      </div>
      <div className="testimonial-slider">
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <div key={index}>
              <div className="testmonial-content">
                <div className="testimonial-section">
                  <div className='has-text-white' style={{fontSize: 'clamp(0.9rem , 1.1vw , 1.3rem)', textAlign: "left", lineHeight: "1.3", fontFamily: "Montserrat", fontWeight: "700"}}>
                    <cite style={{whiteSpace: "normal"}}>{testimonial.quote}</cite>
                  </div>
                  <div>
                    <p className="testmonial-content-author">
                      {width > 768 ? (<><hr /> &nbsp;{testimonial.author}</>) : (<><span><sup>__</sup>&nbsp;{testimonial.author}</span></>)}
                    </p>
                    <p className='testmonial-content-profession'>{testimonial.profession}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div className="testimonial-end-quote" >
        <img className="end-quote" src={testimonials[0]?.endQuote} alt="Quote End" />
      </div>
    </section>
  );
};

Testimonials.propTypes = {
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      quote: PropTypes.string,
      author: PropTypes.string,
      profession: PropTypes.string,
      caption: PropTypes.string,
      startQuote: PropTypes.string,
      endQuote: PropTypes.string,
    })
  ).isRequired,
};

export default Testimonials;
