import React, { useContext } from 'react';
import { Link } from "gatsby";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import "../style/index.sass";
import useWindowSize from '../hooks/useWindowSize';
import { GlobalContext } from '../context/GlobalContext';

const Features = ({features}) => {
    const { width } = useWindowSize()
    const chunkedFeatures = [];
    for (let i = 0; i < features.length; i += 3) {
        chunkedFeatures.push(features.slice(i, i + 3));
    }
    return (
        <div className="solution-features-container">
          {chunkedFeatures.map((featurePair, containerIndex) => (
            <div className="solution-container-section" key={containerIndex}>
              {featurePair.map((feature, index) => {
                const image = getImage(feature.icon);
                const image2 = getImage(feature.icon2);
                return (
                  <div key={feature.index} className={`solution-feature solution-feature${index + 1}`} style={{ backgroundColor: feature.backgroundColor }}
                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = feature.hoverBackgroundColor}
                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = feature.backgroundColor}>
                    { width > 768 && image && <GatsbyImage image={image} className={`solution-feature-image${index+1} image is-square solution-icon-with-hover`} style={{paddingTop: "0%", width: "50px", height: "50px"}} />}
                    {image2 && <GatsbyImage image={image2} className={`solution-feature-image${index+1} image is-square solution-icon-without-hover`} style={{paddingTop: "0%", width: (width > 768 ? "50px": "30px"), height: (width > 768 ? "50px": "30px")}} />}
                    <p className='solution-feature-title'>{feature.title}</p>
                    <p className={`solution-hover-text${index+1}`}>{feature.hoverText}</p>
                  </div>
                );
              })}
            </div>
          ))}
        </div>
    )
}

const CarouselCard = (carousel) => {
    const { width } = useWindowSize()
    const context = useContext(GlobalContext);
    const handleClick = async (newPath) => {
        if(newPath.search("therapy") != -1){
            await context.setState({ path: newPath, section: "section2" });
        }
    }
    const backgroundStyle = carousel.orangeSlide || width < 769
        ? {
            background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${carousel.item.img}) no-repeat center center/cover`
        }
        : {
            backgroundColor: "black"
        };
    return (
        <div key={carousel.index} style={{ ...backgroundStyle, backgroundSize:'cover', padding: carousel.orangeSlide ? '5%' : '0%',display:'flex', flexDirection:'row'}} className='carousel-card fit-page is-justify-content-center'>
            <div className='is-flex is-flex-direction-row is-justify-content-center therapy-carousel-card' style={{width: carousel.orangeSlide ? (width > 768 ? '80%': "90%") : "100%"}}>
                <div style={{
                    height: carousel.orangeSlide ? "80%" : "100%",
                    display: 'flex',
                    flexDirection: 'column',
                    width: !carousel.orangeSlide ? (width > 768 ? '50%': "80%") : (width > 768 ? '55%': "50%"),
                    backgroundColor: !carousel.orangeSlide ? (width > 768 ? 'black': null) : null,
                    textAlignLast: !carousel.orangeSlide ? 'center' : 'left',
                    alignItems: !carousel.orangeSlide ? 'center' : 'start',
                    justifyContent: !carousel.orangeSlide ? (width > 768 ? 'start': 'center') : (width > 768 ? 'start': 'space-between'),
                    paddingTop: !carousel.orangeSlide ? (width > 768 ? '5%': '0') : '0',
                    }}>
                    <p style={{ fontSize: carousel.orangeSlide ? 'clamp(1rem, 3vw, 3.5rem)': 'clamp(2rem, 3.2vw, 3.5rem)', fontWeight: 'bold', color:carousel.orangeSlide ? '#E65E27':'#177E89', width: carousel.orangeSlide ? carousel.item.width :'85%'}}>{carousel.item.title}</p>
                    <p className="mt-4" style={{color:'white', fontSize:  carousel.orangeSlide ? "clamp(0.8rem , 1.3vw , 1.4rem)" : "clamp(1rem , 1.3vw , 1.4rem)", fontWeight: "500", width: (width > 768 ? '80%': "100%") ,textAlign: carousel.orangeSlide ? 'start' : 'center'}}>{carousel.item.description}</p>
                    {!carousel.orangeSlide ? (
                        <div className="disease-list-grid mt-4">
                            {carousel.item?.diseasesList?.map((disease, index) => (
                            <div key={index} className="disease-item">
                                <div>&#10003;</div>
                                <div>{disease.name}</div>
                            </div>
                            ))}
                        </div>
                    ): (<></>)}
                    <div className={`is-12 ${width > 768 ? 'mt-6': 'mt-4'}`} >
                        <Link className="btn" to={carousel.item.path} onClick={() => handleClick(carousel.item.path)} style={{backgroundColor: carousel.orangeSlide ? '#E65E27':'#177E89',color:'white', boxShadow:'none' , fontSize: "clamp(0.7rem , 1vw , 1rem)"}}>
                            EXPLORE MORE
                        </Link>
                    </div>
                </div>
                <div style={{width:!carousel.orangeSlide ? (width > 768 ? '50%': null) : (width > 768 ? '45%': '50%'), height: carousel.orangeSlide ? "80%" : "100%", display:'flex',backgroundSize:'cover',backgroundPosition: 'center',backgroundImage: !carousel.orangeSlide ? `url(${carousel.item.img})`: null}} className="is-flex is-flex-wrap-wrap is-flex-direction-row is-justify-content-center">
                    { carousel.item.icons && <Features features={carousel.item.icons} />}
                </div>
            </div>
        </div>
    )
}

export default CarouselCard;