import React, { useContext } from "react";
import "../style/global-style.sass";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers , faHandshake , faFileLines } from '@fortawesome/free-solid-svg-icons';
import useWindowSize from "../hooks/useWindowSize";
import { Link } from "gatsby";
import { GlobalContext } from '../context/GlobalContext';

const greaterCircle = (width) => {
  return {
    position: 'relative',
    width: width > 768 ? 'clamp(14rem , 17.5vw , 18.5rem)' : '6rem',
    height: width > 768 ? 'clamp(14rem , 17.5vw , 18.5rem)' : '6rem',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
};

const circle1 = (width) => {
  return {
    content: '',
    position: 'absolute',
    top: width > 768 ? '0.4375rem' : '0.18rem',
    left: '0',
    width: '100%',
    height: '95%',
    borderRadius: '50%',
    boxSizing: 'border-box',
    border: width > 768 ? '0.25rem solid grey' : '0.15rem solid grey',
    clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
  };
};

const circleafter = {
  content: '',
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  borderRadius: '50%', 
  boxSizing: 'border-box',
  clipPath: 'polygon(0% 0%, 50% 0%, 50% 100%, 0% 100%)',
}


const FeatureGrid = ({ item , bg , gridItems ,color , bgColor , fontcolor}) => {

  const { width } = useWindowSize();
  const circle = greaterCircle(width)
  const circlebefore= circle1(width)
  const context = useContext(GlobalContext);
  const handleClick = async (e, newPath, newClass) => {
    await context.setState({ path: newPath, section: newClass });
  }
  return(
 
  <div className="features-container-1">
    <video disablePictureInPicture
      src={bg}
      autoPlay muted loop
      type="video/mp4"
      className="features-video-container"
    ></video>

    <div className='features-content'>
      <div className="features-title">
        <span style={{color: `${color}` }}>{item.title1}</span>
        <span class="has-text-weight-bold" style={{ color: `${fontcolor}` }}>{' '}{item.title2}</span>
      </div>
      <div className="features-des">
        <p>{item.description}</p>
      </div>

      <div className="features-grid">
        {gridItems.map((item,index) => {
          return (
              <div style={{...circle}} key={index}>
                <div style={{...circlebefore}}></div>
                <div style={{...circleafter , border: (width > 768 ? `clamp(0.8rem , 1.3vw , 1.5rem) solid ${item.color}` : `0.4rem solid ${item.color}`)}}></div>
                      
                <div className='features-circle'>
                  <div className="features-inside-1" style={{ color: `${item.color}`}}>
                      {item.logo === 'faUsers' && <FontAwesomeIcon icon={faUsers} style={{height: `clamp(1.5rem , 2.3vw,2.8rem)`}}/>}
                      {item.logo === 'faHandshake' && <FontAwesomeIcon icon={faHandshake} style={{height: `clamp(1.5rem , 2.3vw,2.8rem)`}}/>}
                      {item.logo === 'faFileLines' && <FontAwesomeIcon icon={faFileLines} style={{height: `clamp(1.5rem , 2.3vw,2.8rem)`}}/>}
                    <p className="has-text-weight-bold features-inside-1-sub">{item.percent}</p>
                    <p style={{fontFamily:'Montserrat', fontSize: `clamp(0.46rem , 1.3vw , 1.213rem)`, fontWeight: "500", padding: "0% 5%" }}>{item.title}</p>
                  </div>  
                </div>
              </div>
           
          )})}
      </div>
      <Link to="/contact"><button className="features-button btn" style={{ color: `${color}` , backgroundColor: `${bgColor}` }} onClick={(e) => handleClick(e, "/contact", "section2")}>{item.button}</button></Link>
    </div>
  </div>
)};

export default FeatureGrid;
